// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "stylesheets/application.scss"

import "components/datepickers.js"
// import "components/slider.js"
import "components/rentals/show.js"
import "components/cookies_bar.js"
// import "components/rentals/search.js"
// import "vendor/nouislider.min"
import noUiSlider from "nouislider"
// if ( window.noUiSlider === undefined) {
  window.noUiSlider = noUiSlider
// }
// window.noUiSlider = noUiSlider
// import * as noUiSlider from "nouislider"
// // import "vendor/nouislider.min.css"

// import SmoothScroll from "smooth-scroll"   // TODO should check what it is
//   window.SmoothScroll = SmoothScroll

import Swiper, { Navigation, Pagination } from 'swiper'
window.Swiper = Swiper
// import * as noUiSlider from 'nouislider'
// import "nouislider"
import "components/theme"
import "magnific-popup"
import "jquery.cookie"
import objectFitImages from "object-fit-images"
window.objectFitImages = objectFitImages
import "bootstrap"
import "bootstrap-select"
import "leaflet"

import "moment"
import "components/jquery.daterangepicker0.21.min"

// import "components/map-layers"
// import "components/map-detail"
// import "components/datepicker-detail"
// import "components/datepicker-category"

// import Blazy from "blazy"
import lazysizes from 'lazysizes'

$(document).on("turbo:load", function() {

})

// new Blazy(
//   {
//     src: 'data-lazysrc',
//     breakpoints: [
//       {
//         width: 420,
//         src: 'data-lazysrcsm'
//       }
//     ],
//     success: (element) => {
//       setTimeout(() => {
//         let parent = element.parentNode
//         parent.className = parent.className.replace(/\bloading\b/, '')
//       }, 300)
//     }
//   }
// )

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
