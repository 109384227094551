import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css"
import "bootstrap-datepicker"

import dayjs from 'dayjs';
// import moment from "moment";

document.addEventListener("turbo:load", function() {
  $.fn.datepicker.defaults.format = "yyyy-mm-dd";
  $.fn.datepicker.defaults.todayHighlight = true;
  $.fn.datepicker.defaults.autoclose = true;

  // ensure valid dates if user selects any
  $('#checkin').on('change', function (e) {
    let checkin = $(e.target).val()
    let checkout = $('#checkout').val()
    checkin = new Date(checkin)
    if(checkout == '' || (checkout != '' && checkin >= new Date(checkout))) {
      $('#checkout').datepicker('update', (dayjs(checkin).add(1,'days').format('YYYY-MM-DD')))
    }
  })

  $('#checkout').on('change', function (e) {
    let checkout = $(e.target).val()
    let checkin = $('#checkin').val()
    if(new Date(checkout) <= new Date(checkin) || checkin == '') {
      $('#checkin').datepicker('update', (dayjs(checkout).subtract(1, 'days').format('YYYY-MM-DD')))
    }
  })

  var singleMonth = false;
  if ($(window).width() < 668) {
      singleMonth = true;
  }

  var dateRangeConfig = {
      autoClose: true,
      stickyMonths: true,
      startDate: new Date(),
      selectForward: true,
      applyBtnClass: "btn btn-primary",
      container: ".datepicker-container",
      inline: true,
      singleMonth: singleMonth,
      showDateFilter: function (time, date) {
          return (
              '<div style="padding:5px;">\
                          <span style="">' +
              date +
              "</span>\
                      </div>"
          )
      },
      customOpenAnimation: function (cb) {
          $(this).fadeIn(300, cb);
      },
      customCloseAnimation: function (cb) {
          $(this).fadeOut(300, cb);
      },
      hoveringTooltip: function(days)
      {
        var D = ['','1 night', '2 nights','3 nights','4 nights','5 nights','6 nights','7 nights','8 nights'];
        return D[days] ? D[days-1] : days-1;
      },
  };


  $("#form_dates").dateRangePicker(dateRangeConfig);

})
